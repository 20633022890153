import React, { useState } from 'react'
import Button from '../button/Button'
import './ScrollTop.css';

function ScrollTop() {
    const [visible, setVisible] = useState(false) 
  
  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 300){ 
      setVisible(true) 
    }  
    else if (scrolled <= 300){ 
      setVisible(false) 
    } 
  }; 
  
  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth'
    }); 
  }; 
  
  window.addEventListener('scroll', toggleVisible); 
  return (
    <div className='scroll-top' style={{display: visible ? 'inline' : 'none'}}>
        <Button  text='&#8593;' type='primary' onClick={scrollToTop}/>
    </div>
  )
}

export default ScrollTop