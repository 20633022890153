import React from 'react'
import './Icon.css'

interface Props {
    icon: string,
    iconType?: string,
}

function Icon({icon, iconType}: Props) {
    if(!iconType){
        iconType = 'social-icon'
    }
    return (
        <span className='icon'>
        { 
            iconType === "social-icon" ? 
                (<img alt='Social Icon' src={`/images/social-icons/${icon}.svg`} />) : 
                (<img alt='Icon' src={`/images/${icon}.svg`} />)
        }
        </span>
    )
}

export default Icon