import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import Layout from "./pages/Layout/Layout";
import Home from "./pages/Home";
import Service from "./pages/Service";
import Blogs from "./pages/Blogs";
import Portfolio from "./pages/Portfolio";
import PortfolioDetail from "./pages/PortfolioDetail";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import { RoutesConstant } from "./constants/routes";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={RoutesConstant.HOME} element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={RoutesConstant.SERVICE} element={<Service />} />
          <Route path={RoutesConstant.BLOGS} element={<Blogs />} />
          <Route path={RoutesConstant.PORTFOLIO} element={<Portfolio />} />
          <Route path={RoutesConstant.PORTFOLIO_DETAIL} element={<PortfolioDetail />} />
          <Route path={RoutesConstant.CONTACT} element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root')  as HTMLElement);
root.render(<App />);
