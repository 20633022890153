import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { Container } from 'react-bootstrap'
import Icon from '../icon/Icon'
import './Footer.css'
import { HashLink } from 'react-router-hash-link';
import Nav from 'react-bootstrap/Nav';
import Button from '../button/Button';
import { RoutesConstant } from '../../constants/routes';


function Footer() {
    const activeRoute = useLocation().pathname;
    let year = new Date().getFullYear();
    const [showSubSuccessMsg, setSubSuccessMsg] = useState(false);
  return (
    <>
        <footer>
            <div className="footer-top">
                <h4>Have a project in mind?</h4>
                <Nav.Link><HashLink to={RoutesConstant.HOME === activeRoute ? '#contact' : '/?sec=contact'} > <Button  text='Get Quote' type='primary' onClick={ (e)=> console.log(e)} /> </HashLink></Nav.Link>
                {/* <button className="btn btn-primary">Book Now</button> */}
            </div>
            <Container>
                <div className="row">
                    <div className="col-md-3">
                        <div className="logo">
                            <img src="/images/logo/logo-footer.png" alt="Coven's Tech Logo" />
                        </div>
                        <p>We are a team of highly qualified wizards capable of helping you grow your business digitally with the mobile app, website, or any custom development in a magical way.
                        </p>
                        <div className="social-icons">
                            <Icon icon='facebook-f'/>
                            <Icon icon='twitter'/>
                            <Icon icon='linkedin-in'/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h6>Contact</h6>
                        <span className='text-with-icon'>
                            <Icon icon="location" iconType="icon" />
                            <span className='location-container'>
                                <p className='mb-0'>Unit 6/2, Clapham Road,</p>
                                <p className='mb-0'>Hughesdale, Melbourne, VIC,</p>
                                <p className='mb-0'>Australia - 3166.</p>
                            </span>
                        </span>
                        <span className='text-with-icon'>
                            <Icon icon="email" iconType="icon" />
                            <span className='phone-number'>info@covenstech.com</span>
                        </span>
                        <span className='text-with-icon'>
                            <Icon icon="email" iconType="icon" />
                            <span className='phone-number'>covenstech@gmail.com</span>
                        </span>
                        <span className='text-with-icon'>
                            <Icon icon="call" iconType="icon" />
                            <span className='phone-number'>+61 416398156</span>
                        </span>
                        <span className='text-with-icon'>
                            <Icon icon="call" iconType="icon" />
                            <span className='phone-number'>+91 9979872376</span>
                        </span>
                    </div>
                    <div className="col-md-3 quick-links">
                        <h6>Viewer guides</h6>
                        <HashLink smooth to={RoutesConstant.HOME === activeRoute ? '#home' : '/?sec=home'} > Home </HashLink>
                        <HashLink smooth to={RoutesConstant.HOME === activeRoute ? '#services' : '/?sec=services'} > Service </HashLink>
                        <HashLink smooth to={RoutesConstant.HOME === activeRoute ? '#about-us' : '/?sec=about-us'} > About us </HashLink>
                        <HashLink smooth to={RoutesConstant.HOME === activeRoute ? '#portfolio' : '/?sec=portfolio'} > Portfolio </HashLink>
                        <HashLink smooth to={RoutesConstant.HOME === activeRoute ? '#contact' : '/?sec=contact'} > Contact Us </HashLink>
                    </div>
                    <div className="col-md-3">
                        <h6>Singup for monthly newsletter</h6>
                        <form className="subscription-form form-inline" onSubmit={e => {e.preventDefault(); setSubSuccessMsg(true)}}>
                            <div className="form-group">
                                <input type="email" className="form-control" id="subscription-email" placeholder="Email" />
                            </div>
                            <button type="submit" className="btn btn-primary"><Icon icon="send" iconType="icon" /></button>
                        </form>
                        { showSubSuccessMsg && (<span>Thank you for subscribing!</span>)}
                    </div>
                </div>
            </Container>
        </footer>
        <div className='footer-bottom'>
            <Container>
                <p>&copy; {year} all rights reserved.</p>
                <p>Designed by Coven's Tech</p>
            </Container>
        </div>
    </>
  )
}


export default Footer