import React, { useEffect, useState } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import './PortfolioDetail.css';
import { Link } from 'react-router-dom';
import ScrollTop from '../components/scroll-top/ScrollTop';

function PortfolioDetail() {
    const navigate = useNavigate();
    const id = parseInt(useParams().id || "0");
    const [portfolio, setPortfolio] = useState<any>({});
    const [prevPortfoio, setPrevPortfolio] = useState<any>({});
    const [nextPortfolio, setNextPortfolio] = useState<any>({});


    useEffect(()=>{
        fetch('/portfolios.json', {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
          }).then((response) => {
            return response.json();
          }).then((ports) => {
            let currPortIndex:number = -1;
            const currPortfolio = ports.body.filter((p:any, index:number)=>{
                if(p.id === id){
                    currPortIndex = index;
                    return true;
                }
                return false;
            });
            if(currPortfolio.length){
                currPortfolio[0].other_images = currPortfolio[0].other_images.map((img:string)=>{
                        return window.location.origin+"/images/portfolio/"+currPortfolio[0].folder+'/'+img
                })
                setPortfolio(currPortfolio[0]);

                if(currPortIndex >= 1){
                    setPrevPortfolio(ports.body[currPortIndex - 1]);
                }else{
                    setPrevPortfolio({})
                }

                if(currPortIndex <= (ports.body.length - 2)){
                    setNextPortfolio(ports.body[currPortIndex + 1]);
                }else{
                    setNextPortfolio({})
                }
            }else{
                navigate("/404")
            }
          });
    }, [id, navigate])

  return (
    <>
        <section id='portfolio-details'>
            <br />
            <br />
            <Container>
                <h1 className='text-center title'>{portfolio.title}</h1>
                <h4 className='text-center category'>{portfolio.category}</h4>
                <div className="card-details">
                    {
                        portfolio.other_images &&
                            <Carousel>
                                { portfolio.other_images.map((ii:any, index:number)=>{
                                    return (<Carousel.Item key={index}>
                                        <img width={'100%'} height={'700px'} style={{objectFit: 'cover', objectPosition: 'top'}} src={ii} alt=''/>
                                    </Carousel.Item>)
                                })}
                            </Carousel>
                    }
                    <div className='details-wrapper row text-justified'>
                        <div className="col-md-12 col-sm-12 content">
                            <br />
                            <br />
                            <div dangerouslySetInnerHTML={{__html: portfolio.description}}></div>
                            { portfolio.link && (<>Link: <a rel="noreferrer" href={portfolio.link} target='_blank'>{portfolio.link}</a></>)}
                        </div>
                    </div>
                    <div className="pagination">
                        <div className="previous">
                            {
                                prevPortfoio.title && (
                                    <Link to={'/portfolio-detail/'+prevPortfoio.id}>
                                        &#8592; {prevPortfoio.title}
                                        </Link>
                                )
                            }
                        </div>
                        <div className="next">
                        {
                                nextPortfolio.title && (
                                    <Link to={'/portfolio-detail/'+nextPortfolio.id}>
                                        {nextPortfolio.title} &#8594;
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Container>
        </section>
        <ScrollTop />
    </>
  )
}

export default PortfolioDetail