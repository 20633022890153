import React, { useState, useEffect, useRef } from 'react'
import Button from '../components/button/Button'
import './Home.css'
import { Container } from 'react-bootstrap'
import Icon from '../components/icon/Icon'
import { HashLink } from 'react-router-hash-link';
import Nav from 'react-bootstrap/Nav';
import ScrollTop from '../components/scroll-top/ScrollTop'

function useQuery() {
    return new URLSearchParams(window.location.search);
}

function Home() {
    const section = useQuery().get('sec');
    const homeRef = useRef<null | HTMLDivElement>(null);
    const serviceRef = useRef<null | HTMLDivElement>(null);
    const aboutRef = useRef<null | HTMLDivElement>(null);
    const portfolioRef = useRef<null | HTMLDivElement>(null);
    const contactRef = useRef<null | HTMLDivElement>(null);
    const [activeCard, setActiveCard] = useState("app-dev");
    const [activePorfolioCat, setActivePorfolioCat] = useState("all");

    interface portInterface {
        title: string;
        link: string;
        image: string;
        category: string;
        id: number;
        folder: string;
        description: string;
        other_images: String[];
    };
    const [portfolios, setPortfolios] = useState({
        'web': [],
        'app': [],
        'dbms': [],
        'all': []
    });

    const scrollToElement = () => {
        switch (section) {
            case 'home':
                setTimeout(()=>{
                    homeRef.current?.scrollIntoView({ behavior: 'smooth' });
                }, 300)
                break;
            case 'services':
                setTimeout(()=>{
                    serviceRef.current?.scrollIntoView({ behavior: 'smooth' });
                }, 300)
                break;
            case 'about-us':
                setTimeout(()=>{
                    aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
                }, 300)
                break;
            case 'portfolio':
                setTimeout(()=>{
                    portfolioRef.current?.scrollIntoView({ behavior: 'smooth' });
                }, 300)
                break;
            case 'contact':
                setTimeout(()=>{
                    contactRef.current?.scrollIntoView({ behavior: 'smooth' });
                }, 300)
                break;
            default:
                break;
        }
    };

    const sendMail = (e:any)=>{
        e.preventDefault();
        let form = (document.forms as any).contactForm;
        let formData = new FormData(form);
        let body = encodeURIComponent(`name: ${formData.get('name')}\nemail: ${formData.get('email')}\nmessage: ${formData.get('message')}`);
        let subject = encodeURIComponent(`${formData.get('subject')}`);

        let emailElm = document.createElement('a');
        emailElm.setAttribute('href', `mailto:info@covenstech.com?subject=${subject}&body=${body}`);
        emailElm.click();
    }

    
    
    useEffect(() => {
        scrollToElement();
    });
    useEffect(() => {
        const fetchPortfolios = () => {
            fetch('/portfolios.json', {
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                 }
              }).then((response) => {
                return response.json();
              }).then((ports) => {
                ports = ports.body ? ports.body : ports;
                let tempPorts = {
                    'web': [],
                    'app': [],
                    'dbms': [],
                    'all': []
                } as any;
                (ports as Array<portInterface>).forEach(item => {
                    tempPorts[item.category].push(item); 
                    tempPorts['all'].push(item);
                });
                setPortfolios(tempPorts);
              });
        }
        fetchPortfolios();
    }, []);

  return (
    <>
        <section id='home' className='main-banner' ref={homeRef}>
            <Container>
                <div className="row ">
                    <div className="col-lg-6 left-side">
                        <h2>
                            We are The Brilliants in <br />
                            all IT Solutions. <br />
                        </h2>
                        <p>Get in touch to grow your business digitally.</p>
                        <br />
                        <Nav.Link><HashLink smooth to='#contact' > <Button  text='Get in touch' type='secondary' onClick={ (e)=> console.log(e)} /> </HashLink></Nav.Link>
                    </div>
                    <div className="col-lg-6 right-side">
                        <img src="/images/banner1.png" alt="Banner" />
                    </div>
                </div>
            </Container>
        </section>
        <section id='services' ref={serviceRef}>
            <Container className='text-center'>
                <h3 className='text-center small-title'>Services</h3>
                <h4>What We Offer</h4>
                <p>We offer solutions for all your digital problems/requirement.
                    <br/> Check (Not limited to) some services listed below.</p>
                <div className='service-cards'>
                    <div className={`card text-center ${activeCard === "app-dev" ? "active": ""}`} onClick={()=>setActiveCard('app-dev')}>
                        <Icon icon="mobile-app" iconType="icon" />
                        <span>App Development</span>
                    </div>
                    <div className={`card text-center ${activeCard === "web-dev" ? "active": ""}`} onClick={()=>setActiveCard('web-dev')}>
                        <Icon icon="web-development" iconType="icon" />
                        <span>Website Development</span>
                    </div>
                    <div className={`card text-center ${activeCard === "design" ? "active": ""}`} onClick={()=>setActiveCard('design')}>
                        <Icon icon="figma" iconType="icon" />
                        <span>Designing</span>
                    </div>
                    <div className={`card text-center ${activeCard === "seo" ? "active": ""}`} onClick={()=>setActiveCard('seo')}>
                        <Icon icon="seo" iconType="icon" />
                        <span>SEO</span>
                    </div>
                    <div className={`card text-center ${activeCard === "dbms" ? "active": ""}`} onClick={()=>setActiveCard('dbms')}>
                        <Icon icon="sql" iconType="icon" />
                        <span>Database Management</span>
                    </div>
                </div>

                <div className="service-card-details">
                    {
                        activeCard === 'app-dev' && (<div className='app-dev details-wrapper row'>
                        <div className="col-md-6 col-sm-12 image">
                            <img src="/images/imgs/app-dev.webp" alt="add dev" />
                        </div>
                        <div className="col-md-6 col-sm-12 content padding-2-rem">
                            <h2>Mobile Application Development</h2>
                            <p>Mobile app development involves designing, creating, and optimizing software applications for smartphones and tablets. It encompasses various platforms like iOS and Android, offering users convenient access to services, information, and entertainment. The process includes planning, coding, testing, and ongoing updates to ensure functionality, user experience, and compatibility. Successful mobile app development requires a deep understanding of user needs, cutting-edge technologies, and a focus on delivering seamless, engaging, and valuable experiences to users.</p>
                            <Nav.Link><HashLink smooth to='#contact' > <Button  text='Learn More' type='primary' onClick={ (e)=> console.log(e)} /> </HashLink></Nav.Link>
                        </div>
                    </div>)}
                    {
                        activeCard === 'web-dev' && (<div className='web-dev details-wrapper row'>
                        <div className="col-md-6 col-sm-12 image">
                            <img src="/images/imgs/web-dev.webp" alt="web dev" />
                        </div>
                        <div className="col-md-6 col-sm-12 content padding-2-rem">
                            <h2>Website Development</h2>
                            <p>Website development is the art and science of building and maintaining websites. It encompasses front-end design, user interface, back-end programming, and database management. Web developers use languages like HTML, CSS, JavaScript, and various frameworks to create visually appealing, functional, and responsive sites. SEO, security, and user experience optimization are also crucial aspects of web development.</p>
                            <Nav.Link><HashLink smooth to='#contact' > <Button  text='Learn More' type='primary' onClick={ (e)=> console.log(e)} /> </HashLink></Nav.Link>
                        </div>
                    </div>)
                    }
                    {
                        activeCard === 'design' && (<div className='design details-wrapper row'>
                        <div className="col-md-6 col-sm-12 image">
                            <img src="/images/imgs/design.jfif" alt="design" />
                        </div>
                        <div className="col-md-6 col-sm-12 content padding-2-rem">
                            <h2>App & Web design</h2>
                            <p>App and web design involves crafting visually appealing and user-friendly interfaces for digital platforms. Designers use elements like layout, color schemes, typography, and interactive elements to create engaging user experiences. They focus on aesthetics, accessibility, and intuitive navigation to ensure that apps and websites are both visually captivating and functionally effective.</p>
                            <Nav.Link><HashLink smooth to='#contact' > <Button  text='Learn More' type='primary' onClick={ (e)=> console.log(e)} /> </HashLink></Nav.Link>
                        </div>
                    </div>)
                    }
                    {
                        activeCard === 'seo' && (<div className='seo details-wrapper row'>
                            <div className="col-md-6 col-sm-12 image">
                                <img src="/images/imgs/SEO.jpg" alt="SEO" />
                            </div>
                            <div className="col-md-6 col-sm-12 content padding-2-rem">
                                <h2>Search Engine Optimisation</h2>
                                <p>SEO, or Search Engine Optimization, is the practice of optimizing online content to improve its visibility in search engine results. It involves using relevant keywords, quality content, and effective backlinks to rank higher in search rankings. SEO enhances organic traffic, user experience, and website authority, driving online success for businesses.</p>
                                <Nav.Link><HashLink smooth to='#contact' > <Button  text='Learn More' type='primary' onClick={ (e)=> console.log(e)} /> </HashLink></Nav.Link>
                            </div>
                        </div>)
                    }
                    {
                        activeCard === 'dbms' && (<div className='dbms details-wrapper row'>
                        <div className="col-md-6 col-sm-12 image">
                            <img src="/images/imgs/dbms.png" alt="dbms" />
                        </div>
                        <div className="col-md-6 col-sm-12 content padding-2-rem">
                            <h2>Database Management System</h2>
                            <p>A Database Management System (DBMS) is software that enables efficient storage, retrieval, and management of data. It provides tools for creating, modifying, and querying databases, ensuring data integrity and security. DBMSs are crucial for businesses, helping them organize and access vast amounts of information, making data-driven decisions feasible.</p>
                            <Nav.Link><HashLink smooth to='#contact' > <Button  text='Learn More' type='primary' onClick={ (e)=> console.log(e)} /> </HashLink></Nav.Link>
                        </div>
                    </div>)
                    }
                </div>
            </Container>
        </section>
        <section id="about-us" ref={aboutRef}>
            <Container >
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <img src="/images/imgs/about-us1.png" alt="about us" />
                    </div>
                    <div className="col-md-6 col-sm-12 padding-2-rem">
                        <h3 className='small-title'>About Us</h3>
                        <h2>We are Here<br /> For Business Solution Idea</h2>
                        <p>Welcome to Coven's Tech, your trusted partner in the ever-evolving world of technology. With a relentless commitment to innovation and excellence, we are dedicated to delivering cutting-edge IT solutions that empower businesses to thrive in the digital age.</p>
                        <p>Our journey began 2020, and since then, we have been at the forefront of IT services, offering a wide spectrum of solutions tailored to meet the unique needs of our clients. Our team of seasoned professionals brings a wealth of experience and expertise to every project, ensuring top-notch quality and reliability.</p>
                        <p>At Coven's Tech, our mission is simple yet profound: to transform businesses through technology. We believe that technology should be an enabler, driving growth, efficiency, and success for our clients. We are committed to understanding your goals and challenges, and then crafting bespoke solutions that propel your business forward.</p>
                        <Nav.Link><HashLink smooth to='#contact' > <Button  text='Learn More' type='primary' onClick={ (e)=> console.log(e)} /> </HashLink></Nav.Link>
                    </div>
                </div>
            </Container>
        </section>
        <section>
            <Container>
                <div className='summary box-shadow'>
                    <div className="clients">
                        <p className='numbers'>40+</p>
                        <p>Happy Clients</p>
                    </div>
                    <div className="projects">
                        <p className='numbers'>120+</p>
                        <p>Projects Completed</p>
                    </div>
                    <div className="awards">
                        <p className='numbers'>130</p>
                        <p>Awards Won</p>
                    </div>
                    <div className="team">
                        <p className='numbers'>20+</p>
                        <p>Team Members</p>
                    </div>
                </div>
            </Container>
        </section>
        <section id="portfolio" ref={portfolioRef}>
            <Container>
                <h3 className='text-center small-title'>Portfolio</h3>
                <h4 className='text-center'>Our Work</h4>
                <div className="portfolio-categories">
                    <Button  text='All development' type={activePorfolioCat === 'all' ? 'primary' : 'secondary'} onClick={ (e)=> setActivePorfolioCat('all')} />
                    <Button  text='Web development' type={activePorfolioCat === 'web' ? 'primary' : 'secondary'} onClick={ (e)=> setActivePorfolioCat('web')} />
                    <Button  text='App development' type={activePorfolioCat === 'app' ? 'primary' : 'secondary'} onClick={ (e)=> setActivePorfolioCat('app')} />
                    <Button  text='DBMS' type={activePorfolioCat === 'dbms' ? 'primary' : 'secondary'} onClick={ (e)=> setActivePorfolioCat('dbms')} />
                </div>
                <div className='portfolios padding-2-rem'>
                    <div className="row">
                        {
                            activePorfolioCat === 'all' &&
                            (
                                portfolios.all.length ? portfolios.all.map((portfolio:portInterface, index)=>{
                                return ( 
                                <div className="portfolio-item col-md-4" key={index}>
                                    <img className={portfolio.category === 'web' ? 'slide-up' : ''} src={"/images/portfolio/"+portfolio.image} alt={portfolio.image} />
                                    <h3><a role='button' href={'/portfolio-detail/'+portfolio.id}>{portfolio.title}</a></h3>
                                </div>)
                            }): (
                                <p className='text-center'>No records found.</p>
                            ))
                        }
                        {
                            activePorfolioCat === 'web' &&
                            (
                                portfolios.web.length ? portfolios.web.map((portfolio:portInterface, index)=>{
                                return portfolio.category === 'web' && ( 
                                <div className="portfolio-item col-md-4" key={index}>
                                    <img className='slide-up' src={"/images/portfolio/"+portfolio.image} alt={portfolio.image} />
                                    <h3><a role='button' href={'/portfolio-detail/'+portfolio.id}>{portfolio.title}</a></h3>
                                </div>)
                            }) : (
                                <p className='text-center'>No records found.</p>
                            ))
                        }
                        {
                            activePorfolioCat === 'app' &&
                            (
                                portfolios.app.length ? portfolios.app.map((portfolio:portInterface, index)=>{
                                return portfolio.category === 'app' && ( 
                                <div className="portfolio-item col-md-4" key={index}>
                                    <img src={"/images/portfolio/"+portfolio.image} alt={portfolio.image} />
                                    <h3><a role='button' href={'/portfolio-detail/'+portfolio.id}>{portfolio.title}</a></h3>
                                </div>)
                            }) : (
                                <p className='text-center'>No records found.</p>
                            ))
                        }
                        {
                            activePorfolioCat === 'dbms' && (
                                portfolios.dbms.length ? 
                                portfolios.dbms.map((portfolio:portInterface, index)=>{
                                    return ( 
                                    <div className="portfolio-item col-md-4" key={index}>
                                        <img src={"/images/portfolio/"+portfolio.image} alt={portfolio.image} />
                                        <h3><a role='button' href={'/portfolio-detail/'+portfolio.id}>{portfolio.title}</a></h3>
                                    </div>)
                                }) : (
                                    <p className='text-center'>No records found.</p>
                                )
                            )
                        }
                        
                    </div>
                </div>
            </Container>
        </section>
        <section id='contact' ref={contactRef}>
        <Container >
                <div className="row">
                    <div className="col-md-6 col-sm-12 padding-2-rem">
                        <h3 className='text-center small-title'>Contact Us</h3>
                        <p className="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within a matter of hours to help you.</p>
                        <form id='contactForm' onSubmit={($event)=>sendMail($event)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="md-form mb-0">
                                        <label htmlFor="name" className="">Your name</label>
                                        <input type="text" id="name" name="name" className="form-control" required/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="md-form mb-0">
                                        <label htmlFor="email" className="">Your email</label>
                                        <input type="email" id="email" name="email" className="form-control" required/>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="md-form mb-0">
                                        <label htmlFor="subject" className="">Subject</label>
                                        <input type="text" id="subject" name="subject" className="form-control" required/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">

                                    <div className="md-form">
                                        <label htmlFor="message">Your message</label>
                                        <textarea id="message" name="message" rows={2} className="form-control md-textarea" required></textarea>
                                    </div>

                                </div>
                            </div>
                            <br/>
                            <Button actionType='submit' text='Send Now' type='primary' onClick={ (e)=>{ }} />
                        </form>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <img src="/images/imgs/about-us1.png" alt="about us" />
                    </div>
                </div>
            </Container>
        </section>
        <ScrollTop />
    </>
  )
}

export default Home