import React from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavLink from 'react-bootstrap/NavLink'
import './Layout.css';
import Footer from '../../components/footer/Footer';
import { RoutesConstant } from '../../constants/routes';

function Layout() {
    const activeRoute = useLocation().pathname;
    return (
        <>
            <Navbar expand="lg" variant='dark' bg='trasparent'>
                <Container>
                    <Navbar.Brand href="/">
                        <img className='navbar-logo' src="/images/logo/logo-no-background.svg" alt="Coven's tech logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="me-auto"></Nav>
                        <Nav
                            className="my-2 my-lg-0"
                            style={{ maxHeight: '200px' }}
                            navbarScroll
                        >
                            <NavLink><HashLink smooth to={RoutesConstant.HOME === activeRoute ? '#home' : '/?sec=home'} > Home </HashLink></NavLink>
                            <NavLink><HashLink smooth to={RoutesConstant.HOME === activeRoute ? '#services' : '/?sec=services'} > Service </HashLink></NavLink>
                            <NavLink><HashLink smooth to={RoutesConstant.HOME === activeRoute ? '#about-us' : '/?sec=about-us'} > About us </HashLink></NavLink>
                            <NavLink><HashLink smooth to={RoutesConstant.HOME === activeRoute ? '#portfolio' : '/?sec=portfolio'} > Portfolio </HashLink></NavLink>
                            <NavLink><HashLink smooth to={RoutesConstant.HOME === activeRoute ? '#contact' : '/?sec=contact'} > Contact Us </HashLink></NavLink>
                            {/* <Nav.Link><HashLink smooth to='#home' > Home </HashLink></Nav.Link> */}
                            {/* <Nav.Link><HashLink smooth to='#services' > Service </HashLink></Nav.Link> */}
                            {/* <Nav.Link><HashLink smooth to='#about-us' > About us </HashLink></Nav.Link> */}
                            {/* <Nav.Link><HashLink smooth to='#blog' > Blogs </HashLink></Nav.Link> */}
                            {/* <Nav.Link><HashLink smooth to='#portfolio' > Portfolio </HashLink></Nav.Link> */}
                            {/* <Nav.Link><HashLink smooth to='#contact' > Contact Us </HashLink></Nav.Link> */}
                            {/* <Nav.Link><Link to="/blogs">Blogs</Link></Nav.Link>
                            <Nav.Link><Link to="/portfolio">Portfolio</Link></Nav.Link>
                            <Nav.Link><Link to="/contact">Contact</Link></Nav.Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
            <Footer />
        </>
    )
}

export default Layout