import React from 'react'

function NoPage() {
  return (
    <div className='text-center'>
      <br />
      <br />
      <img height={250} src="/images/error-404.png" alt="404" />
      <h1>404: Page Not Found</h1>
      <p>Page you are looking for can not be found.</p>
      <br />
      <br />
    </div>
  )
}

export default NoPage