import React from 'react';

interface Props {
    text: string;
    type: 'primary' | 'secondary' | 'default';
    actionType?: 'button' | 'submit' | 'reset';
    onClick: (e: React.MouseEvent<HTMLElement>)=> void;
}

function Button({text, type, onClick, actionType} : Props) {
  actionType = actionType ? actionType : 'button'
  return (
    <button type={actionType} className={`btn btn-${type}`} onClick={onClick}>{text}</button>
  )
}

export default Button